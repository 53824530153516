import React, { useState } from 'react'
import axios from 'axios'

import { url } from '../info.js'

const Profile = () => {
	const [user, setUser] = useState({});
	const [view, setView] = useState('profile')



	const [editError, setEditError] = useState('')
	const [guestbookError, setGuestbookError] = useState('')
	const [adminPointError, setAdminPointError] = useState('')

	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [text, setText] = useState('')
	const [userId, setUserId] = useState('')
	const [userInfo, setUserInfo] = useState({})
	const [userPoint, setUserPoint] = useState(0)

	function getProfile() {
		axios.get(`${url}/profile/` , {})
		.catch(function (error) {
			console.error(error)
		})
		.then(function (response) {
			if (response.data.success) {
				setUser(response.data.user)
				setFirstname(response.data.firstname)
				setLastname(response.data.lastname)
			}
		})
	}

	const handleEdit = (e) => {
		var data = { 
			firstname: firstname?firstname:user.firstname,
			lastname: lastname?lastname:user.lastname
		}
		axios.put(`${url}/profile/` , data)
		.catch(function (error) {
			setEditError(error.response.data.message)
		})
		.then(function (response) {
			if (response.data.success) {
				getProfile()
				setView('profile')
			}
		})
		e.preventDefault()
	}

	const handleGuestbook = (e) => {
		axios.post(`${url}/guestbook/` , { text })
		.catch(function (error) {
			setGuestbookError(error.response.data.message)
		})
		.then(function (response) {
			if (response.data.success) {
				setView('profile')
			}
		})
		e.preventDefault()
	}

	const handleDelete = (e) => {
		axios.delete(`${url}/profile/` , {})
		.catch(function (error) {
			console.log(error)
			window.location.reload(false)
		})
		.then(function (response) {
			if (response.data.success) {
				window.location.reload(false)
			}
		})
		e.preventDefault()
	};	

	const handleLogout = (e) => {
		axios.get(`${url}/logout/` , {})
		.catch(function (error) {
			window.location.reload(false)
		})
		.then(function (response) {
			if (response.data.success) {
				window.location.reload(false)
			}
		})
		e.preventDefault()
	};	

	const handleReset = (e) => {
		axios.get(`${url}/reset/` , { email: user.email })
		.catch(function (error) {
			console.log(error)
		})
		.then(function (response) {
			if (response.data.success) {
				setView('profile')
			}
		})
		e.preventDefault()
	};

	const handleAdminPoint = (e) => {
		setAdminPointError('')
		axios.post(`${url}/getclient/` , { id: userId })
		.catch(function (error) {
			console.log(error)
			setAdminPointError('Utilisateur non trouvé')
		})
		.then(function (response) {
			if (response.data.success) {
				setUserPoint(response.data.client.points)
				setUserInfo({
					id: userId,
					points: response.data.client.points,
					name: response.data.client.firstname + ' ' + response.data.client.lastname,
					newletter: false
				})
				setUserId('')
			} else {
				setAdminPointError('Utilisateur non trouvé')
			}
		})
		e.preventDefault()
	}

	const handleSetAdminPoint = (e) => {
		axios.put(`${url}/client/` , { id: userInfo.id, points: userPoint })
		.catch(function (error) {
			console.log(error)
			setAdminPointError(error.response.data.message)
		})
		.then(function (response) {
			if (response.data.success) {
				setView('profile')
				setUserInfo({})
			} else { 
				setAdminPointError('Erreur réseau')
			}
		})
		e.preventDefault()
	}

	React.useEffect(() => {
		getProfile()
	}, [])

	if(view === 'profile') {
		if(user.status === 10) {
			return (
				<div className="flex flex-col gap-8 py-8">
					<h1 className="text-3xl mx-auto text-green font-bold">Bonjour {user.firstname}</h1>					
					<div className="flex flex-col gap-4 mt-auto">
						<button onClick={() => setView('adminPoint')}>Modifier des 🌟</button>
						<button onClick={() => setView('adminGuestbook')}>Valider des avis</button>
						<button onClick={() => setView('edit')}>Modifier mon profil</button>
						<button onClick={() => setView('logout')}>Se déconnecter</button>
					</div>
				</div>
			)
		} else {
			return (
				<div className="flex flex-col gap-8 py-8">
					<h1 className="text-3xl mx-auto text-green font-bold">Bonjour {user.firstname}</h1>
					<h2 className="text-2xl mx-auto text-green font-bold">Tu as {user.points} 🌟</h2>
					<h2 className="text-2xl mx-auto text-green font-bold" >Ton identifiant est le {user.id}</h2>
					
					<div className="flex flex-col gap-4 mt-auto">
						<button onClick={() => setView('guestbook')}>Publier un avis</button>
						<button onClick={() => setView('edit')}>Modifier mon profil</button>
						<button onClick={() => setView('logout')}>Se déconnecter</button>
					</div>
				</div>
			)
		}
	} else if(view === 'edit') {
		return (
			<div className="flex flex-col gap-8 py-8">
				<h1 className="text-4xl mx-auto text-green font-bold">Modifier mon profil</h1>
				<form onSubmit={handleEdit} className="flex flex-col gap-6">
					<h2 className="text-center text-red font-medium text-2xl">{editError}</h2>
					<input
						type="text"
						placeholder="Prénom"
						value={firstname?firstname:user.firstname}
						onChange={(e) => setFirstname(e.target.value)}
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<input
						type="text"
						placeholder="Nom de famille"
						value={lastname?lastname:user.lastname}
						onChange={(e) => setLastname(e.target.value)}
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<input type="submit" value="Modifier" className="bg-green text-2xl text-white border-green border-4 rounded-3xl text-center"/>
				</form>
				<div className="flex flex-col gap-4 mt-auto">
					<button onClick={() => setView('profile')}>Annuler</button>
					<button onClick={() => setView('reset')}>Changer de mot de passe</button>
					<button onClick={() => setView('delete')}>Supprimer mon compte</button>
				</div>
			</div>
		)
	} else if(view === 'delete') {
		return (
			<div className="flex flex-col gap-8 py-8">
				<h1 className="text-3xl mx-auto text-green font-bold">Supprimer mon compte</h1>
				<h2 className="text-2xl mx-auto text-green font-bold">Es-tu sûr de vouloir supprimer ton compte ?</h2>
				<h2 className="text-2xl mx-auto text-green font-bold" >Les points seront perdu...</h2>
				<button onClick={handleDelete}>Supprimer</button>
				<div className="flex flex-col gap-4 mt-auto">
					<button onClick={() => setView('profile')}>Annuler</button>
					<button onClick={() => setView('logout')}>Se déconnecter</button>
				</div>
			</div>
		)
	} else if(view === 'logout') {
		return (
			<div className="flex flex-col gap-8 py-8">
				<h1 className="text-3xl mx-auto text-green font-bold">Me déconnecter</h1>
				<h2 className="text-2xl mx-auto text-green font-bold">Es-tu sûr de vouloir te déconnecter ?</h2>
				<button onClick={handleLogout}>Se deconnecter</button>
				<div className="flex flex-col gap-4 mt-auto">
					<button onClick={() => setView('profile')}>Annuler</button>
				</div>
			</div>
		)
	} else if(view === 'reset') {
		return (
			<div className="flex flex-col gap-8 py-8">
				<h1 className="text-3xl mx-auto text-green font-bold">Changer mon mot de passe</h1>
				<h2 className="text-2xl mx-auto text-green font-bold">Un lien sera envoyé par email</h2>
				<button onClick={handleReset}>Envoyer</button>
				<div className="flex flex-col gap-4 mt-auto">
					<button onClick={() => setView('profile')}>Annuler</button>
				</div>
			</div>
		)
	} else if (view === 'guestbook'){
		return (
			<div className="flex flex-col gap-8 py-8">
				<h1 className="text-4xl mx-auto text-green font-bold">Publier un avis</h1>
				<form onSubmit={handleGuestbook} className="flex flex-col gap-6">
					<h2 className="text-center text-red font-medium text-2xl">{guestbookError}</h2>
					<textarea
						type="text"
						placeholder="Text"
						onChange={(e) => setText(e.target.value)}
						required
						className="bg-light text-lg text-green border-green border-4 rounded-3xl text-center"
					></textarea>
					<input type="submit" value="Publier" className="bg-green text-2xl text-white border-green border-4 rounded-3xl text-center"/>
				</form>
				<div className="flex flex-col gap-4 mt-auto">
					<button onClick={() => setView('profile')}>Annuler</button>
				</div>
			</div>
		)
	} else if(view === 'adminPoint') {
		if(userInfo && userInfo.id) {
		return (
			<div className="flex flex-col gap-8 py-8">
				<h1 className="text-4xl mx-auto text-green font-bold">Modifier des 🌟</h1>
				<form onSubmit={handleSetAdminPoint} className="flex flex-col gap-6">
					<h2 className="text-center text-red font-medium text-2xl">{adminPointError}</h2>
					<h2 className="text-center text-green font-medium text-2xl">{userInfo.name} a {userInfo.points} 🌟</h2>
					<input
						type="numeric"
						placeholder="🌟"
						value={userPoint}
						onChange={(e) => setUserPoint(e.target.value)}
						maxLength="99"
						minLength="0"
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<div className="flex flex-col gap-4 mt-auto">
						<input type="submit" value="Sauvegarder" className="bg-green text-2xl text-white border-green border-4 rounded-3xl text-center"/>
						<button onClick={() => setUserInfo({})} className="bg-red text-2xl text-white border-red border-4 rounded-3xl text-center">Retour</button>
					</div>
				</form>
			</div>
		)
		} else {
			return (
				<div className="flex flex-col gap-8 py-8">
					<h1 className="text-4xl mx-auto text-green font-bold">Modifier des 🌟</h1>
					<form onSubmit={handleAdminPoint} className="flex flex-col gap-6">
						<h2 className="text-center text-red font-medium text-2xl">{adminPointError}</h2>
						<input
							type="numeric"
							placeholder="ID"
							value={userId?userId:''}
							onChange={(e) => setUserId(e.target.value)}
							required
							className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
						/>
						<input type="submit" value="Rechercher" className="bg-green text-2xl text-white border-green border-4 rounded-3xl text-center"/>
					</form>
					<div className="flex flex-col gap-4 mt-auto">
						<button onClick={() => setView('profile')}>Retour</button>
					</div>
				</div>
			)
		}
	} else if(view === 'adminGuestbook') {
		return null
	} else {
		return null
	}
}

export default Profile;
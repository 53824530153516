import React, { useState } from 'react'
import axios from 'axios'
import { useParams } from "react-router-dom";

import { url } from '../info.js'

const Reset = () => {
	const { slug } = useParams();

	const [resetError, setEditError] = useState('')

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')


	const handleReset = (e) => {
		var data = { 
			email: email,
			password: password,
			token: slug
		}
		axios.post(`${url}/password/` , data)
		.catch(function (error) {
			setEditError(error.response.data.message)
		})
		.then(function (response) {
			if (response.data.success) {
				// redirect to login
				window.location.href = '/'
			}
		})
		e.preventDefault()
	}

	return (
		<div className="flex flex-col gap-8 py-8">
				<h1 className="text-4xl mx-auto text-green font-bold">Changement de mot de passe</h1>
				<form onSubmit={handleReset} className="flex flex-col gap-6">
					<h2 className="text-center text-red font-medium text-2xl">{resetError}</h2>
					<input
						type="email"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<input
						type="password"
						placeholder="Mot de passe"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<input type="submit" value="Changer de mot de passe" className="bg-green text-2xl text-white border-green border-4 rounded-3xl text-center"/>
				</form>
			</div>
	)
};

export default Reset;
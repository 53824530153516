import React from 'react'


const Offline = () => {
	return (
		<div className="flex flex-col gap-8 py-8">
				<h1 className="text-4xl m-auto text-green font-bold text-center">
					Pas de connexion internet détectée
				</h1>
			</div>
	)
};

export default Offline;
import React, { useState } from 'react'
import axios from 'axios'

import { url } from '../info.js'

const Login = () => {
	const [view, setView] = useState('login')

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')

	const [loginError, setLoginError] = useState('');
	const [registerError, setRegisterError] = useState('');
	const [forgotError, setForgotError] = useState('');

	const handleLogin = (e) => {
		axios.post(`${url}/login/` , { email, password })
		.catch(function (error) {
			console.log(error)
			setLoginError(error.response.data.message)
		})
		.then(function (response) {
			if (response.data.success) {
				window.location.reload(false)
			}
		})
		e.preventDefault()
	};

	const handleRegister = (e) => {
		axios.post(`${url}/register/` , { email, password, firstname, lastname})
		.catch(function (error) {
			setRegisterError(error.response.data.message)
		})
		.then(function (response) {
			if (response.data.success) {
				window.location.reload(false)
			}
		})
		e.preventDefault()
	};	

	const handleForgot = (e) => {
		axios.post(`${url}/resetpassword/` , { email })
		.catch(function (error) {
			setForgotError(error.response.data.message)
		})
		.then(function (response) {
			if (response.data.success) {
				setForgotError(response.data.message)
			}
		})
		e.preventDefault()
	};
	
	if(view === 'login') {
		return (
			<div className="flex flex-col gap-8 py-8">
				<h1 className="text-4xl mx-auto text-green font-bold">Connexion</h1>
				<form onSubmit={handleLogin} className="flex flex-col gap-6">
					<h2 className="text-center text-red font-medium text-2xl">{loginError}</h2>
					<input
						type="email"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<input
						type="password"
						placeholder="Mot de passe"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<input type="submit" value="Se connecter" className="bg-green text-2xl text-white border-green border-4 rounded-3xl text-center"/>
				</form>
				<button onClick={() => setView('register')}>Je n'ai pas de compte</button>
				<button onClick={() => setView('forgot')}>Mot de passe oublié</button>
			</div>
		)
	} else if(view === 'register') {
		return (
			<div className="flex flex-col gap-8 py-8">
				<h1 className="text-4xl mx-auto text-green font-bold">Enregistrement</h1>
				<form onSubmit={handleRegister} className="flex flex-col gap-6">
					<h2 className="text-center text-red font-medium text-2xl">{registerError}</h2>
					<input
						type="email"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<input
						type="password"
						placeholder="Mot de passe"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
						minLength="8"
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<input
						type="text"
						placeholder="Prénom"
						value={firstname}
						onChange={(e) => setFirstname(e.target.value)}
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<input
						type="text"
						placeholder="Nom de famille"
						value={lastname}
						onChange={(e) => setLastname(e.target.value)}
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<p className="text-center">En m'enregistrant j'accepte que mes données soient traitées conformément au RGPD et qu'elle soient utilisées uniquement pour le bon fonctionnement de l'application app.oceazen.be</p>
					<input type="submit" value="S'enregistrer" className="bg-green text-2xl text-white border-green border-4 rounded-3xl text-center"/>
				</form>
				<button onClick={() => setView('login')}>J'ai un compte</button>
			</div>
		)
	} else if(view === 'forgot') {
		return (
			<div className="flex flex-col gap-8 py-8">
				<h1 className="text-4xl mx-auto text-green font-bold">Mot de passe oublié</h1>
				<form onSubmit={handleForgot} className="flex flex-col gap-6">
					<h2 className="text-center text-red font-medium text-2xl">{forgotError}</h2>
					<input
						type="email"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
						className="bg-light text-2xl text-green border-green border-4 rounded-3xl text-center"
					/>
					<input type="submit" value="Envoyer un code par mail" className="bg-green text-2xl text-white border-green border-4 rounded-3xl text-center"/>
				</form>
				<button onClick={() => setView('login')}>J'ai un mot de passe</button>
			</div>
		)
	}
}

export default Login;
import React from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { url } from './info.js';

import Login from './components/login';
import Profile from './components/profile';
import Reset from './components/reset';
import Offline from './components/offline';

import logo from './logo.png';

function App() {
	const [page, setPage] = React.useState('')

	function isConnected() {
		try {
			axios.get(`${url}/ping/` , {})
				.catch(function (error) {
					console.error(error)
					setPage('offline')
				})
				.then(function (response) {
					if (response.data.success) {
						axios.get(`${url}/profile/` , {})
							.catch(function (error) {
								console.error(error)
								setPage('login')
							})
							.then(function (response) {
								if (response.data.success) {
									setPage('profile')
								} else {
									setPage('login')
								}
							})
					} else {
						setPage('offline')
					}
				})
		} catch (error) {
			console.error(error)
			setPage('login')
		}
	}

	React.useEffect(() => {
		isConnected()
	}, [])
	return (
		<BrowserRouter>
      <Routes>
				<Route path="/reset/:slug" element={
					<div className="flex h-screen w-screen p-0 lg:p-12 bg-light">
					<div className="flex flex-col w-full lg:max-w-max lg:w-1/2 xl:w-1/3 h-full lg-h:auto lg:aspect-[12/9] rounded-none lg:rounded-3xl m-auto p-6 bg-light drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] overflow-y-auto">
						<img src={logo} alt="logo" className="w-56 mx-auto" />
						<Reset />
					</div>
				</div>
				} />
				<Route index element={
					<div className="flex h-screen w-screen p-0 lg:p-12 bg-light">
						<div className="flex flex-col w-full lg:max-w-max lg:w-1/2 xl:w-1/3 h-full lg-h:auto lg:aspect-[12/9] rounded-none lg:rounded-3xl m-auto p-6 bg-light drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] overflow-y-auto">
							<img src={logo} alt="logo" className="w-56 mx-auto" />
							{page === 'login' && <Login />}
							{page === 'profile' && <Profile />}
							{page === 'offline' && <Offline />}
						</div>
					</div>
				} />
      </Routes>
    </BrowserRouter>
	);
}
export default App;
